<template>
	<div>
		<div class="demo-upload-list" v-for="(item,index) in uploadList" :key="index">
			<template v-if="item.status === 'finished'">
				<img :src="item.url">
				<div class="demo-upload-list-cover">
					<Icon type="ios-eye-outline" @click.native="viewImage(item.name)"></Icon>
					<Icon type="ios-trash-outline" @click.native="delImage(item)"></Icon>
				</div>
			</template>
			<template v-else>
				<Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
			</template>
		</div>
		<Upload ref="upload" :show-upload-list="showUploadList" :default-file-list="defaultList" :max-size="maxSize"
			:multiple="multiple" :type="type" :action="action" :accept="accept" :format="format" :data="data"
			:on-success="handleSuccess" :on-format-error="handleFormatError" :on-exceeded-size="handleMaxSize"
			:before-upload="handleBeforeUpload">
			<slot name="custom">
				<div style="width: 75px;height:75px;line-height: 75px;">
					<Icon type="md-add" size="20"></Icon>
				</div>
			</slot>
		</Upload>
		<Modal title="预览" v-model="showModel" :footer-hide="true">
			<img :src="'https://o5wwk8baw.qnssl.com/' + imgName + '/large'" v-if="showModel" style="width: 100%">
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'Uploads',
		props: {
			// 是否显示已上传文件列表
			showUploadList: {
				type: Boolean,
				default: false
			},
			// 已上传的文件列表
			defaultList: {
				type: Array,
				default: function() {
					return [{
							'name': 'a42bdcc1178e62b4694c830f028db5c0',
							'url': 'https://o5wwk8baw.qnssl.com/a42bdcc1178e62b4694c830f028db5c0/avatar'
						},
						{
							'name': 'bc7521e033abdd1e92222d733590f104',
							'url': 'https://o5wwk8baw.qnssl.com/bc7521e033abdd1e92222d733590f104/avatar'
						}
					]
				}
			},
			// 上传时额外参数 可自定义 object格式
			data: {
				type: Object,
				// eslint-disable-next-line vue/require-valid-default-prop
				default: function() {
					return {}
				}
			},
			// 上传的文件字段名
			fileName: {
				type: String,
				default: 'file'
			},
			// 上传的地址，必填
			action: {
				type: String,
				required: true,
				default: '//jsonplaceholder.typicode.com/posts/'
			},
			// 是否支持多选文件
			multiple: {
				default: false,
				type: Boolean
			},
			// 上传控件的类型，可选值为 select（点击选择），drag（支持拖拽）
			type: {
				type: String,
				default: 'drag'
			},
			// 支持的文件类型 audio/* 表示音频文件 video/* 表示视频文件 image/* 表示图片文件
			accept: {
				type: String,
				default: 'image/*'
			},
			// 支持的文件类型
			format: {
				type: Array,
				default: function() {
					return ['jpg', 'jpeg', 'png']
				}
			},
			// 文件大小限制，单位 kb
			maxSize: {
				type: Number,
				default: 1024 * 1024 * 10
			},
			length: {
				type: Number,
				default: 5
			}
		},
		data() {
			return {
				showModel: false,
				uploadList: [],
				imgName: ''
			}
		},
		methods: {
			// 图片预览
			viewImage(name) {
				console.log(name)
			},
			// 移除图片列表
			delImage(item) {
				console.log(item)
			},
			// 文件上传成功时的钩子
			handleSuccess(res) {
				this.$emit('upload_succ', res);
			},
			// 文件格式验证失败时的钩子
			handleFormatError(file) {
				console.log(file)
				this.$Notice.warning({
					title: '提示信息',
					desc: '上传的文件类型错误！'
				})
			},
			// 文件超出指定大小限制时的钩子
			handleMaxSize(file) {
				this.$Notice.warning({
					title: '提示信息',
					desc: '文件  ' + file.name + ' 大小超出限制' + this.maxSize
				})
			},
			// 上传文件之前的钩子
			handleBeforeUpload() {
				const check = this.uploadList.length < this.length;
				if (!check) {
					this.$Notice.warning({
						title: '上传数量不能超出' + this.length
					})
				}
				return check
			}
		}
	}
</script>

<style scoped>
	.demo-upload-list {
		display: inline-block;
		width: 60px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
		margin-right: 4px;
	}

	.demo-upload-list img {
		width: 100%;
		height: 100%;
	}

	.demo-upload-list-cover {
		display: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, .6);
	}

	.demo-upload-list:hover .demo-upload-list-cover {
		display: block;
	}

	.demo-upload-list-cover i {
		color: #fff;
		font-size: 20px;
		cursor: pointer;
		margin: 0 2px;
	}
</style>
