<template>
	<div class="saas-body">
		<div class="saas-search">
			<Form :model="SearchForm" @submit.native.prevent inline>
				<!-- <FormItem>
					<Select @on-change="init" style="width:200px" v-model="SearchForm.type">
						<Option value="1">111</Option>
					</Select>
				</FormItem> -->
				<FormItem>
					<Input @on-search="init" enter-button placeholder="请输入搜索名称" search type="text"
						v-model="SearchForm.name"></Input>
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-add" @click="addSaas">添加用户</Button>
				</FormItem>
			</Form>
		</div>
		<div class="saas-table">
			<Table :columns="columns" :data="data" :loading="table_loading">
				<template slot="action" slot-scope="{row}">
					<a href="javascript:;" @click="renewApply(row.id)" style="margin-right: 15px;">续费</a>
					<a href="javascript:;" @click="edit(row.id)" style="margin-right: 15px;">编辑</a>
					<a href="javascript:;" v-if="row.status" @click="saas_del(row.id)"
						style="margin-right: 15px;">禁用</a>
					<a href="javascript:;" v-else @click="enable(row.id,row.uname)">启用</a>
				</template>
			</Table>
			<Page :current="SearchForm.page" :page-size="SearchForm.limit" :page-size-opts="[10,15,20]"
				:total="SearchForm.total" @on-change="set_current" @on-page-size-change="set_per" class-name="page-r"
				show-sizer show-total></Page>
		</div>
		<Modal :loading="saas_edit_loading" :title="is_edit?'修改saas客户':'添加saas客户'" v-model="saas_edit_show">
			<Form :label-width="100" :model="ruleForm" :rules="rules" label-colon label-position="right"
				ref="edit_saas">
				<FormItem class="modal-item" label="头像上传" prop="head_img">
					<img v-if="ruleForm.head_img" class="thumb" :src="ruleForm.head_img" alt="" />
					<Uploads class="fr-upload" :data="{dir:'store/temp'}" @upload_succ="upload_succ"
						:action="apiUrl+'/adm/upload_img'"></Uploads>
				</FormItem>
				<FormItem class="modal-item" label="昵称" prop="uname">
					<Input placeholder="请输入昵称" v-model="ruleForm.uname" />
				</FormItem>

				<FormItem class="modal-item" label="手机号" prop="phone">
					<Input placeholder="请输入11位手机号" maxlength="11" v-model="ruleForm.phone" />
				</FormItem>
				<FormItem class="modal-item" label="密码" prop="upwd">
					<Input type="password" :disabled="is_edit" maxlength="12" placeholder="请输入6~12位登录密码"
						v-model="ruleForm.upwd" />
				</FormItem>
				<FormItem class="modal-item" label="默认应用版本" prop="apply">
					<Select v-model="ruleForm.apply" :disabled="is_edit">
						<Option v-for="item in versionList" :value="item.id" :key="item.id">{{ item.title }}</Option>
					</Select>
				</FormItem>
				<FormItem v-if="!ruleForm.is_authorize" class="modal-item" label="授权到期时间">
					<DatePicker v-model="ruleForm.out_date" type="date" format="yyyy年MM月dd日" placeholder="授权到期时间"
						style="width: 200px"></DatePicker>
				</FormItem>
				<FormItem class="modal-item" label="永久授权">
					<RadioGroup v-model="ruleForm.is_authorize" @on-change="changeAuthorize">
						<Radio :label="1">开启</Radio>
						<Radio :label="0">关闭</Radio>
					</RadioGroup>
				</FormItem>
				<FormItem class="modal-item" label="续费折扣">
					<i-Switch size="large" :true-value="1" :false-value="0" v-model="ruleForm.discount"
						@on-change="changeDiscount">
						<span slot="open">开启</span>
						<span slot="close">关闭</span>
					</i-Switch>
				</FormItem>
				<FormItem v-if="ruleForm.discount" class="modal-item" label="折扣金额">
					<Input v-model="ruleForm.discount_num"></Input>
				</FormItem>
				<FormItem class="modal-item" label="是否同步云端">
					<i-Switch size="large" :true-value="1" :false-value="0" v-model="ruleForm.is_cloud">
						<span slot="open">同步</span>
						<span slot="close">关闭</span>
					</i-Switch>
				</FormItem>

			</Form>
			<div slot="footer">
				<Button type="primary" @click="edit_saas_ok">{{is_edit?'修改':'添加'}}</Button>
				<Button @click="saas_edit_show=false">取消</Button>
			</div>
		</Modal>

		<!-- saas 续费应用 -->
		<Modal v-model="applyModal.show" title="应用续费" hide-footer width="750">
			<Table :columns="applyModal.columns" :data="applyModal.data" :loading="applyModal.loading" max-height="500">
				<template slot="operate" slot-scope="{row}">
					<Button @click="openRenewModal(row.apply_id)">续费</Button>
				</template>
			</Table>
		</Modal>

		<Modal v-model="applyModal.renewShow" title="续费" @on-cancel="cancelRenew()">
			<Form :label-width="80" label-colon>
				<FormItem label="续费天数">
					<InputNumber :min="1" :max="9999" v-model="applyModal.today" style="width: 200px"></InputNumber>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="cancelRenew()">取消</Button>
				<Button type="primary" @click="confirmRenew()">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	import Uploads from '../../../components/common/Uploads.vue'
	export default {
		components: {
			Uploads
		},
		data() {
			return {
				img: null,
				apiUrl: this.ApiUrl,
				imgUrl: this.serveUrl,
				table_loading: false, //table表格加载状态
				saas_edit_show: false, //对话框开关
				saas_edit_loading: true, //对话框确认按钮加载状态
				is_edit: false,
				data: [],
				versionList: [],
				ruleForm: {
					uname: null,
					upwd: '',
					ip: '',
					apply: 4,
					discount: 0,
					head_img: null,
					discount_num: null,
					is_cloud: 0,
					is_authorize: 0,
					out_date: null
				},
				rules: {
					uname: [{
						required: true,
						message: '请输入客户昵称',
						trigger: 'blur'
					}],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '请填写正确的手机号',
							trigger: 'blur',
							transform(value) {
								var isMobie = /^1[345789]\d{9}$/;
								if (!isMobie.test(value)) {
									return false
								} else {
									return Number(value)
								}
							}
						},
					],
					upwd: [{
						required: true,
						message: '请输入登录密码',
						trigger: 'blur'
					}]
				},
				columns: [{
						title: 'ID',
						key: 'id',
						width: 50
					},
					{
						title: '手机号',
						key: 'phone',
						minWidth: 120
					},
					{
						title: '用户名',
						key: 'uname',
						minWidth: 120
					},
					{
						title: '默认应用版本',
						key: 'apply_name',
						minWidth: 120
					},
					{
						title: '续费折扣',
						key: 'discount',
						minWidth: 100,
						render: (h, params) => {
							return h('Tag', {
								props: {
									color: params.row.discount ? 'success' : 'default'
								}
							}, params.row.discount ? '有' : '无')
						}
					},
					{
						title: '标签',
						key: 'label_text',
						minWidth: 150
					},
					{
						title: '创建时间',
						key: 'create_time',
						minWidth: 140
					},
					{
						title: '到期时间',
						key: 'out_time',
						minWidth: 140
					},
					{
						title: '状态',
						key: 'status',
						minWidth: 70,
						render: (h, params) => {
							return h('Tag', {
								props: {
									color: params.row.status ? 'success' : 'error'
								}
							}, params.row.status ? '正常' : '禁用')
						}
					},
					{
						title: '操作',
						slot: 'action',
						minWidth: 150
					},
				],
				SearchForm: {
					name: null,
					type: null,
					limit: 10,
					page: 1,
					total: 0
				},
				applyModal: {
					loading: false,
					show: false,
					renewShow: false,
					store_id: 0, // saas id
					apply_id: 0, // 续费应用id
					today: 1, // 续费天数
					columns: [{
							title: 'ID',
							key: 'id'
						},
						{
							title: '应用名称',
							key: 'apply_name'
						},
						{
							title: '到期时间',
							key: 'expire_time'
						},
						{
							title: '操作',
							slot: 'operate',
							align: 'center'
						}
					],
					data: []
				}
			}
		},
		created() {
			this.init()
		},
		methods: {
			//数据初始化
			init() {
				var _this = this
				var SearchForm = this.SearchForm
				this.table_loading = true
				this.requestApi('/adm/saas_list', {
					SearchForm,
				}).then(
					(res) => {
						// 应用版本列表
						_this.versionList = res.versionList;
						//加载数据
						_this.data = res.data.data
						//获取总条数
						_this.SearchForm.total = res.data.total
						//获取当前页
						_this.SearchForm.page = res.data.current_page
						//获取每页条数
						_this.SearchForm.limit = res.data.per_page
						//关闭表格加载状态
						_this.table_loading = false
					}
				)
			},
			upload_succ(res) {
				this.$set(this.ruleForm, 'head_img', this.imgUrl + res.pic_url)
			},
			//确认提交
			edit_saas_ok() {
				var _this = this;
				var resdata = JSON.parse(JSON.stringify(this.ruleForm));
				var upwd = md5(resdata.upwd).substr(2, 28);
				resdata.upwd = upwd;
				var is_edit = this.is_edit;
				this.$refs['edit_saas'].validate(valid => {
					if (valid) {
						_this.requestApi('/adm/saas_edit_save', {
							data: resdata,
							type: is_edit
						}).then(
							(res) => {
								if (res.status) {
									_this.saas_edit_show = false;
									_this.$Message.success(res.msg);
									_this.init();
								} else {
									_this.$Message.error(res.msg)
								}
							}
						)
					}
				})
			},
			addSaas() {
				this.is_edit = false;
				this.saas_edit_show = true;
				this.$refs['edit_saas'].resetFields();
				this.ruleForm = {
					uname: null,
					upwd: '',
					ip: '',
					apply: 4,
					discount: 0,
					discount_num: null,
					is_cloud: 0,
					is_authorize: 0,
					out_date: null
				}
			},
			//回显数据
			edit(id) {
				var _this = this;
				this.requestApi('/adm/saas_edit', {
					id
				}).then(
					(res) => {
						if (res.code) {
							_this.ruleForm = res.data;
							_this.is_edit = true;
							_this.saas_edit_show = true;
						}
					}
				)
			},
			changeAuthorize(valid) {
				if (valid == 1) this.ruleForm.out_date = '';
			},
			//切换是否开启折扣
			changeDiscount(valid) {
				if (valid == 0) this.ruleForm.discount_num = null;
			},
			//删除数据
			saas_del(id) {
				var _this = this
				this.$Modal.confirm({
					title: '提示',
					content: '确定禁用该客户吗？',
					onOk: () => {
						this.requestApi('/adm/saas_del', {
							id
						}).then(
							(res) => {
								if (res.status) {
									_this.$Message.success(res.msg)
									_this.init()
								} else {
									_this.$Message.error(res.msg)
								}
							}
						)
					},
					onCancel: () => {

					}
				})
			},
			//切换页数
			set_current(page) {
				this.SearchForm.page = page;
				this.init();
			},
			//切换条数
			set_per(limit) {
				this.SearchForm.page = 1;
				this.SearchForm.limit = limit;
				this.init();
			},
			//启用saas客户
			enable(id, name) {
				let _this = this
				this.$Modal.confirm({
					title: '温馨提示',
					content: '确定启用' + name + '吗？',
					onOk: () => {
						this.requestApi('/adm/enable_saas', {
							id
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.alertSucc(res.msg)
									_this.init()
								} else {
									_this.alertErr(res.msg)
								}
							}
						)
					}
				})
			},
			renewApply(id) {
				let _this = this;
				this.applyModal.store_id = id;
				this.applyModal.loading = true;
				this.requestApi('/adm/get_saas_apply_list', {
					id: id
				}).then((res) => {
					_this.applyModal.loading = false;
					if (res.status == 1) {
						_this.applyModal.data = res.data;
						_this.applyModal.show = true;
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			// 打开续费应用弹窗
			openRenewModal(id) {
				this.applyModal.apply_id = id;
				this.applyModal.renewShow = true;
			},
			// 取消 续费应用
			cancelRenew() {
				this.applyModal.apply_id = 0;
				this.applyModal.today = 1;
				this.applyModal.renewShow = false;
			},
			// 确认 续费应用
			confirmRenew() {
				let _this = this;
				this.requestApi('/adm/renew_saas_apply', {
					apply_id: this.applyModal.apply_id,
					today: this.applyModal.today
				}).then((res) => {
					if (res.status == 1) {
						_this.alertSucc('续费成功');
						_this.cancelRenew();
						_this.renewApply(_this.applyModal.store_id);
					} else {
						_this.alertErr(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped>
	.saas-body {
		background-color: #fff;
		padding: 10px;
	}

	.saas-search {
		background-color: rgb(248, 248, 249);
		padding: 5px;
		margin-bottom: 10px;
	}

	.ivu-form-item {
		margin-bottom: 0px !important;
	}

	.saas-item {
		line-height: 20px;
		color: rgb(96, 98, 102);
	}

	.page-r {
		margin-top: 10px;
		text-align: right;
	}

	.modal-item {
		margin-bottom: 10px !important;
	}

	.fr-upload {
		float: left;
		width: 77px;
		height: 77px;
		box-sizing: border-box;
	}

	.thumb {
		display: block;
		float: left;
		margin-right: 12px;
		width: 77px;
		height: 77px;
	}
</style>